.job-details-container {
    border-radius: 5px;
    z-index: 2;
    border-style: solid;
    width: 50%;
    padding: 2%;
    position: fixed;
    transform:translateX(50%);
    top: 10%;
    background-color: white;
    display: none;

}
.job-detals-overlay {
    display: grid;
}


.job-detals-overlay button {
    color: white;
    position: relative;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #592BD8;
    margin: auto;
}


.job-detals-overlay div {
    padding-left: 5%;
}