.landingPage-story-outerContainer {
    display: grid;
    grid-gap: 5px;
    font-family: 'museo-slab';
}


/* define fonts  */
.landingPage-text h1 {
    /* padding-top: 20px; */
    font-size: 40px;
    font-weight: 500;
}

.landingPage-text h5 {
    font-size: 32px;
    font-weight: 500;
}

.landingPage-col2 .landingPage-text p {
    font-size: 16px;
    font-weight: 100;
}

.landing-page-subtitle {
    font-size: 30px;
    font-weight: 500;
}

.landingPage-headShot-text-link h6, .landingPage-advisor h6{
    font-size: 20px;
    font-weight: 500;
    color: #592BD8;
}

.landingPage-headShot-text-link p, .landingPage-advisor p {
    font-size: 18px;
    font-weight: 100;
}

form span {
    font-size: 14px;
    font-weight: 500;
    color: #592BD8;
}

form input, textarea {
    background-color: #f4f6f8;
}

form button {
    font-size: 16;
    font-weight: 500;
}


/* spacing */

span.picture-credited {
    display:block;
    width: 100%;
    text-align: center;
}

.landingPage-img img {
    width: 400px;
    height: auto;
    display: block;
    margin: auto;
}

.landingPage-col1 {
    display: grid;
    text-align: center;
    grid-gap: 50px;
}

.landingPage-col2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    text-align: center;
}

.left-text {
    padding-left: 10%;
}

.right-text {
    padding-right: 10%;
}

.landingPage-text {
    text-align: justify;
    padding: 5% 10%;
}

.landingPage-col3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    text-align: center;
    grid-gap: 30px;
}


.landingPage-col3 img.headshot {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
/* for landingpage-story-team part */

.landingPage-story-team {
    text-align: center;
    margin: 70px 50px 0px 50px;
}

.landingPage-headShot-text-link h6 {
    margin-top: 20px;
}

/* for landingpage-story-advisors part */
.landingPage-story-advisors {
    text-align: center;
    margin: 70px 50px 0px 50px;
}

.landingPage-advisor {
    padding: 20px;
}

/* for landingpage-story-join part */
.landingPage-story-join  {
    text-align: center;
    margin-top: 70px;
}


/* for landingpage-story-openingjob */
.landingPage-story-openingJobs {
    text-align: center;
    margin-top: 70px;
}

/* for landingPage-story-contact */
.landingPage-story-contact {
    text-align: center;
    /* width: 100%; */
    margin-top: 70px;
    width: 100%;
}

.width-50-percentage {
    margin: auto;
    width: fit-content;
    /* position: relative; */
    left: 10%;
    /* right: 40px; */
    text-align: center;
}

/* .landingPage-story-contact form {
    width: fit-content;
    text-align: center;
    margin: auto;
}
.landingPage-story-contact div {
    width: fit-content;
    text-align: left;
    margin-bottom: 30px;
}
.landingPage-story-contact div span {
    display: block;
}

.landingPage-story-contact form input {
    width: 300px;
    height: 30px;
    box-shadow: 1px 1px #888888;
    border-radius: 5px;
} */

/* .landingPage-story-contact .multiline-input { */
    /* width: 300px;
    height: 30px; */
    /* box-shadow: 1px 1px #888888;
    resize: none;
    border-radius: 5px; */
/* } */

/* .landingPage-story-contact form button {
    margin: auto;
    display: block;
    color: white;
    padding: 5px;
    border-radius: 5px;
    padding-right: 40px;
    padding-left: 40px;
    background-color: #592BD8;
} */
a{
    color: black;
}

.react-responsive-modal-modal  {
    /* background: #592BD8; */
  }