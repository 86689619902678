p, li, a{
    font-family: "museo-slab";
}

.container{
    margin-top: 30px;
}

.navbar{
    margin-bottom: 50px;
}

