.landingPageContent-outerContainer {
    display: grid;
    align-items: center;
    grid-template-rows: 2fr 5fr;
    grid-gap: 50px;
    width: 90%;
    margin: auto;
}
.landingPageContent-first-outerContainer {
    margin:auto;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 17px;
    width: 100%;
    
}


.landingPageContent-second-outerContainer {
    margin:auto;
    display: grid;
    align-items: center;
    grid-template-rows: 1fr 3fr 3fr 1fr;
    grid-gap: 17px;
    width: 90%;
}
.landingPageContent-second-outerContainer-upper {
    text-align: center;
}
.landingPageContent-second-outerContainer-middle {
    margin-top: auto;
    display: grid;
    align-items: center;
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 17px;
    width: 100%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

}
.landingPageContent-second-outerContainer-middle1{
    text-align: center;
}
.landingPageContent-second-outerContainer-middle2{
    text-align: center;
}

.landingPageContent-second-line{
    stroke-linecap: round;
    stroke-width: 3px;
    stroke: #722EFF;
    padding-bottom:25px;
    text-anchor: middle;
}
.landingPageContent-second-t9{
    font-size:18px;
    font-weight: 500;
    text-anchor: middle;
    dominant-baseline: middle;
}
.landingPageContent-second-t15{
    font-size:14px;
    font-weight: 500;
    padding:30px;
    text-anchor: middle;
}
.landingPageContent-second-outerContainer-bottom {
    margin-top: auto;
    display: grid;
    align-items: center;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 17px;
    width: 100%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}
.landingPageContent-outerContainer-button button {
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    background-color: white;
    border: 2px solid #000000;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
}
.landingPageContent-leftContainer {
    max-width: fit-content;
    margin-left: auto;
    padding: 5% 10%;
}

.landingPageContent-leftContainer h1 ~ p {
    text-align: center;
}

.landingPageContent-buttonGroup {
    max-width: fit-content;
    display: inline;
}

.landingPageContent-buttonGroup button {
    margin-right: 10px;
    padding: 5px 10px;
    color: #722EFF;
}
.justify-content-center button{
    margin-bottom: 80px;
    margin-top: 50px;
}

.landingPageContent-rightContainer {
    width: 100%;
}



.landingPageContent-rightContainer img {
    width: auto;
}

.container{
    margin-top: 100px;
    margin-bottom: 100px;   
}
.t1{
    font-weight: 500;
    font-size:40px;
}

.t2{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 30px;
}
.t4{
    font-size:30px;
    font-weight: 500;
}
.t2 center{
    color: #592BD8;
}

.t11{
    font-weight:700;
    font-size:16px;
}
.t12{
    font-weight: 500;
    font-size: 16px;
}
.t14{
    font-size: 16px;
    font-weight: 100;
}

.landingPageContent-innerContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    text-align: center;
}



.left-text {
    padding-left: 70px;
}


.left-image img{
    margin-left: 100px
}

.right-image img {
    margin-left:100px
}

a{
    color: #000;
}

button {
    border-style: none;
    background-color: white;
}
