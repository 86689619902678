/* Global CSS Test */
#react-mathquill-styles {
    color: white;
}

#my-math-input {
    color: white;
    border-color: white;
    background: black;
  }
  #my-math-input .mq-matrixed {
    background: black;
  }
  #my-math-input .mq-matrixed-container {
    filter: progid:DXImageTransform.Microsoft.Chroma(color='black');
  }


span.picture-credited {
  font-family: 'museo-slab';
  font-size: 5px;
  font-weight: 100;
}


span.picture-credited {
  float: right;
}

/* for "credit" span customized*/
.right-100 {
  position: relative;
  right: 120px;
}

.bottom-0 {
  position: absolute;
  bottom: 0px;
}

.top-400 {
  position: relative;
  top: 400px;
}

.top-200 {
  position: relative;
  top: 200px;
}

.top-450 {
  position: relative;
  top: 450px;
}