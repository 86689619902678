


.landingPageHeader-outerContainer {
    margin: 10px 0px;
    font-family:"museo-slab";
}


.landingPageHeader-innerContainer {
    display: grid;
    align-items: center;
    /* grid-template-columns: repeat(auto-fit, minmax(min(100px, 100%), 1fr)); */
    grid-template-columns: 1fr 2fr;
    grid-gap: 17px;
    width: 90%;
    margin: auto;
    height: 100px;
}

.landingPageHeader-imageContainer {
    height: 50px;
    width: 50px;
}

.landingPageHeader-imageContainer img {
    height: 100%;
    width: auto;
}

.landingPageHeader-button1 {
    margin-right: 10px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
}

.landingPageHeader-button2 {
    color: white;
    padding: 5px;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #592BD8;
    font-size: 16px;
    font-weight: 500;
}

.landingPageHeader-buttonGroup {
    margin-left: auto;
    display: inline-block;
    max-width: fit-content;
    /* font-weight: 500;
    font-size: 24; */
}

P.p-link {
    color: #722EFF;
}

a{
    color: #000;
}
button {
    border-style: none;
    background-color: white;
}
p.t2{
    margin-top: 20px;
}
.t1{
    font-weight: 500;
    font-size:40px;
}

.react-responsive-modal-modal  {
    /* color: #ffffff; */
    /* max-width: 400px; */
    width: 50rem;
    max-height: 900px;
    font-family:"museo-slab";
    /* background: #592BD8; */
  }

.react-responsive-modal-modal .landingPageHeader-button2{
    /* background: #ffffff; */
    /* color: #592BD8; */
}
