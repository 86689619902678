.footer{
    background-color: #592BD8;
    /* margin:0px auto; */
    padding: 20px 0px 20px 0px;
    font-family:"museo-slab";
}

hr.style-one { /*内嵌水平线*/
    width:85%;
    margin:0 auto;
    margin-bottom: 15px;
    margin-top: 30px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footer p, li .t12, p .t12{
    color: #ffffff;
}
a{
    color: #ffffff;
}
